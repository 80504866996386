/* eslint-disable object-shorthand */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    singleTemplateSms: "",
    template: null,
    singleSmsHistory: [],
    singleSmsHistoryLoader: null,
    groupSmsHistory: [],
    groupSmsHistoryLoader: null,
    countrySmsHistory: [],
    countrySmsHistoryLoader: null,
    templateHistory: [],
    templateHistoryLoader: null
}

const manageSms = createSlice({
    name: "manageSms",
    initialState,
    reducers: {
        setSingleTemplateSms(state, action) {
            state.singleTemplateSms = action.payload
        },
        setTemplate(state, action) {
            state.template = action.payload
        },
        setSingleSmsHistory(state, action) {
            state.singleSmsHistory = action.payload
        },
        setSingleSmsHistoryLoader(state, action) {
            state.singleSmsHistoryLoader = action.payload
        },
        setGroupSmsHistory(state, action) {
            state.groupSmsHistory = action.payload
        },
        setGroupSmsHistoryLoader(state, action) {
            state.groupSmsHistoryLoader = action.payload
        },
        setCountrySmsHistory(state, action) {
            state.countrySmsHistory = action.payload
        },
        setCountrySmsHistoryLoader(state, action) {
            state.countrySmsHistoryLoader = action.payload
        },
        setTemplateHistory(state, action) {
            state.templateHistory = action.payload
        },
        setTemplateHistoryLoader(state, action) {
            state.templateHistoryLoader = action.payload
        },
        addSmsTemplateToStore(state, action) {
            state.templateHistory.unshift(action.payload)
        },
        deleteTemplates(state, action) {
            const newData = state.templateHistory.filter((item) => {
                return item?.id !== action.payload
            })
            state.templateHistory = newData
        },
        editSmsTemplateStore(state, action) {
            const { id, message, title } = action.payload

            const elemToUpdate = state.templateHistory.findIndex((item) => item.id === id)
            const newArr = [
                ...state.templateHistory.slice(0, elemToUpdate), // everything before current post
                {
                    ...state.templateHistory[elemToUpdate],
                    subject: title,
                    message: message
                },
                ...state.templateHistory.slice(elemToUpdate + 1) // everything after current post
            ]
            // console.log(newArr)
            state.templateHistory = newArr
        },
        singleSmsAdded(state, action) {
            const newData = [action.payload, ...state.singleSmsHistory]
            state.singleSmsHistory = newData
        },
        groupSmsAdded(state, action) {
            const newData = [action.payload, ...state.groupSmsHistory]
            state.groupSmsHistory = newData
        },
        countrySmsAdded(state, action) {
            const newData = [action.payload, ...state.countrySmsHistory]
            state.countrySmsHistory = newData
        }
    }
})

export const {
    setSingleTemplateSms,
    setTemplate,
    setSingleSmsHistory,
    setSingleSmsHistoryLoader,
    setGroupSmsHistory,
    setGroupSmsHistoryLoader,
    setCountrySmsHistory,
    setCountrySmsHistoryLoader,
    setTemplateHistory,
    setTemplateHistoryLoader,
    deleteTemplates,
    singleSmsAdded,
    countrySmsAdded,
    groupSmsAdded,
    editSmsTemplateStore,
    addSmsTemplateToStore
} = manageSms.actions
export default manageSms.reducer
