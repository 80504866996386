/* eslint-disable  object-shorthand */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    localNumberData: [],
    localNumberLoader: null,
    historyData: [],
    historyLoader: null,
    localNumberAvailable: [],
    localNumberAvailableLoader: null
}

const localNumbers = createSlice({
    name: "localNumbers",
    initialState,
    reducers: {
        setLocalNumberData(state, action) {
            state.localNumberData = action.payload
        },
        setLocalNumberLoader(state, action) {
            state.localNumberLoader = action.payload
        },
        setDeleteLocalNumber(state, action) {
            const newArr = state.localNumberData.filter((data) => {
                return data?.id !== action.payload
            })
            state.localNumberData = newArr
        },
        DeleteAvailableLocalNumber(state, action) {
            const newArr = state.localNumberAvailable.filter((data) => {
                return data?.id !== action.payload
            })
            state.localNumberAvailable = newArr
        },
        // setFollowMeAvailableStatus(state, action) {
        //     const { status, id } = action.payload

        //     // console.log("first", { status, id })

        //     const elemToUpdate = state.followMeAvailableData.findIndex((item) => item.id === id)
        //     console.log("elemto update", elemToUpdate)
        //     const newArr = [
        //         ...state.followMeAvailableData.slice(0, elemToUpdate), // everything before current post
        //         {
        //             ...state.followMeAvailableData[elemToUpdate],
        //             status: status
        //         },
        //         ...state.followMeAvailableData.slice(elemToUpdate + 1) // everything after current post
        //     ]
        //     // console.log("newArr", newArr)
        //     state.followMeAvailableData = newArr
        // },
        setHistoryData(state, action) {
            state.historyData = action.payload
        },
        setHistoryLoader(state, action) {
            state.historyLoader = action.payload
        },
        addLocalNumberData(state, action) {
            state.localNumberData = [action.payload, ...state.localNumberData]
        },
        addNewLocalNumberData(state, action) {
            state.localNumberAvailable = [...action.payload, ...state.localNumberData]
        },
        addLocalNumberHistoryData(state, action) {
            state.historyData = [action.payload, ...state.historyData]
        },
        cancelLocalNumberService(state, action) {
            const filter = state.localNumberData.filter(data => {
                return data?.id !== action.payload
            }) 

            state.localNumberData = filter
        },
        setLocalNumberAvailable(state, action) {
            state.localNumberAvailable = action.payload
        },
        setLocalNumberAvailableLoader(state, action) {
            state.localNumberAvailableLoader = action.payload
        },
        setLocalAvailableStatus(state, action) {
            const { status, id } = action.payload

            // console.log("first", { status, id })

            const elemToUpdate = state.localNumberAvailable.findIndex((item) => item.id === id)
            const newArr = [
                ...state.localNumberAvailable.slice(0, elemToUpdate), // everything before current post
                {
                    ...state.localNumberAvailable[elemToUpdate],
                    status: status
                },
                ...state.localNumberAvailable.slice(elemToUpdate + 1) // everything after current post
            ]
            // console.log("newArr", newArr)
            state.localNumberAvailable = newArr
        }
    }
})

export const {
    setLocalNumberData,
    setLocalNumberLoader,
    setDeleteLocalNumber,
    setHistoryData,
    setHistoryLoader,
    addLocalNumberData,
    cancelLocalNumberService,
    addLocalNumberHistoryData,
    setLocalNumberAvailable,
    setLocalNumberAvailableLoader,
    setLocalAvailableStatus,
    DeleteAvailableLocalNumber,
    addNewLocalNumberData
} = localNumbers.actions
export default localNumbers.reducer
