import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    phoneData: [],
    phoneLoader: null,
    voucherData: [],
    voucherLoader: null,
    phoneHistory: [],
    phoneHistoryLoader: null,
    voucherHistory: [],
    voucherHistoryLoader: null
}

const manageCards = createSlice({
    name: "manageCards",
    initialState,
    reducers: {
        setPhoneData(state, action) {
            state.phoneData = action.payload
        },
        setPhoneLoaderStatus(state, action) {
            state.phoneLoader = action.payload
        },
        setVoucherData(state, action) {
            state.voucherData = action.payload
        },
        setVoucherLoader(state, action) {
            state.voucherLoader = action.payload
        },
        setPhoneHistoryData(state, action) {
            state.phoneHistory = action.payload
        },
        setPhoneHistoryLoader(state, action) {
            state.phoneHistoryLoader = action.payload
        },
        setVoucherHistoryData(state, action) {
            state.voucherHistory = action.payload
        },
        setVoucherHistoryLoader(state, action) {
            state.voucherHistoryLoader = action.payload
        },
        updatePhoneCard(state, action) {
            state.phoneData.map((data, index) => {
                if (data.id === action.payload.id) {
                    state.phoneData[index] = action.payload
                }
            })
        },
        updateVoucherCardData(state, action) {
            state.voucherData.map((data, index) => {
                if (data.id === action.payload.id) {
                    state.voucherData[index] = action.payload
                }
            })
        },
        addPhoneCardInRdx(state, action) {
            state.phoneData = [...action.payload, ...state.phoneData]
        },
        addVoucherCardInRdx(state, action) {
            state.voucherData = [...action.payload, ...state.voucherData]
        }
    }
})

export const {
    setPhoneData,
    setPhoneHistoryData,
    setPhoneHistoryLoader,
    setPhoneLoaderStatus,
    setVoucherData,
    setVoucherHistoryData,
    setVoucherHistoryLoader,
    setVoucherLoader,
    updatePhoneCard,
    updateVoucherCardData,
    addPhoneCardInRdx,
    addVoucherCardInRdx
} = manageCards.actions

export default manageCards.reducer
