import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    singleTemplateVoice: "",
    template: null,
    singleVoiceHistory: [],
    singleVoiceHistoryLoader: null,
    groupVoiceHistory: [],
    groupVoiceHistoryLoader: null,
    countryVoiceHistory: [],
    countryVoiceHistoryLoader: null,
    templateVoiceHistory: [],
    templateVoiceHistoryLoader: null
}

const manageVoice = createSlice({
    name: "manageVoice",
    initialState,
    reducers: {
        setSingleTemplateVoice(state, action) {
            state.singleTemplateVoice = action.payload
        },
        setVoiceTemplate(state, action) {
            state.template = action.payload
        },
        setSingleVoiceHistory(state, action) {
            state.singleVoiceHistory = action.payload
        },
        setSingleVoiceHistoryLoader(state, action) {
            state.singleVoiceHistoryLoader = action.payload
        },
        setGroupVoiceHistory(state, action) {
            state.groupVoiceHistory = action.payload
        },
        setGroupVoiceHistoryLoader(state, action) {
            state.groupVoiceHistoryLoader = action.payload
        },
        setCountryVoiceHistory(state, action) {
            state.countryVoiceHistory = action.payload
        },
        setCountryVoiceHistoryLoader(state, action) {
            state.countryVoiceHistoryLoader = action.payload
        },
        setTemplateVoiceHistory(state, action) {
            state.templateVoiceHistory = action.payload
        },
        setTemplateVoiceHistoryLoader(state, action) {
            state.templateVoiceHistoryLoader = action.payload
        },
        deleteVoiceTemplates(state, action) {
            const newData = state.templateVoiceHistory.filter((item) => {
                return item?.id !== action.payload
            })
            state.templateVoiceHistory = newData
        },
        addVoiceTemplateToStore(state, action) {
            state.templateVoiceHistory.unshift(action.payload)
        },
        editVoiceTemplateStore(state, action) {
            const { id, title, file } = action.payload

            const elemToUpdate = state.templateVoiceHistory.findIndex((item) => item.id === id)
            const newArr = [
                ...state.templateVoiceHistory.slice(0, elemToUpdate), // everything before current post
                {
                    ...state.templateVoiceHistory[elemToUpdate],
                    message: file,
                    subject: title
                },
                ...state.templateVoiceHistory.slice(elemToUpdate + 1) // everything after current post
            ]
            // console.log(newArr)
            state.templateVoiceHistory = newArr
        },
        singleVoiceAdded(state, action) {
            const newData = [action.payload, ...state.singleVoiceHistory]
            state.singleVoiceHistory = newData
        },
        groupVoiceAdded(state, action) {
            const newData = [action.payload, ...state.groupVoiceHistory]
            state.groupVoiceHistory = newData
        },
        countryVoiceAdded(state, action) {
            const newData = [action.payload, ...state.countryVoiceHistory]
            state.countryVoiceHistory = newData
        }
    }
})

export const {
    setSingleTemplateVoice,
    setVoiceTemplate,
    setSingleVoiceHistory,
    setSingleVoiceHistoryLoader,
    setGroupVoiceHistory,
    setGroupVoiceHistoryLoader,
    setCountryVoiceHistory,
    setCountryVoiceHistoryLoader,
    setTemplateVoiceHistory,
    setTemplateVoiceHistoryLoader,
    deleteVoiceTemplates,
    singleVoiceAdded,
    groupVoiceAdded,
    countryVoiceAdded,
    editVoiceTemplateStore,
    addVoiceTemplateToStore
} = manageVoice.actions
export default manageVoice.reducer
