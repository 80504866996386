/* eslint-disable  object-shorthand */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    referHistory: [],
    referHistoryLoader: null,
    friendDetail: []
}

const referFriend = createSlice({
    name: "referFriend",
    initialState,
    reducers: {
        setReferHistory(state, action) {
            state.referHistory = action.payload
        },
        setReferHistoryLoader(state, action) {
            state.referHistoryLoader = action.payload
        },
        addReferFriendRdx(state, action) {
            state.referHistory = [...action.payload, ...state.referHistory]
        },
        deleteReferFriends(state, action) {
            const filter = state.referHistory.filter(data => {
                return data?.id !== action.payload
            })
            state.referHistory = filter
        },
        setFriendDetail(state, action) {
            state.friendDetail = action.payload 
        }
    }
})

export const {
    setReferHistory,
    setReferHistoryLoader,
    deleteReferFriends,
    setFriendDetail,
    addReferFriendRdx
} = referFriend.actions
export default referFriend.reducer
