import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    fundData: [],
    fundPaymentLoader: null,
    buyWithCardData: [],
    buyWithCardLoader: null,
    RewardData: [],
    RewardLoader: null,
    RechargeCard: [],
    RechargeCardLoader: null,
    autoRechargeHistory: [],
    autoRechargeLoader: null,
    receiverTransactionHistory: [],
    receiverTransactionLoader: null,
    senderTransactionHistory: [],
    senderTransactionLoader: null,
    buyWithPaypalHistory: [],
    buyWithPaypalLoader: null
}

const customerDataSlice = createSlice({
    name: "customerData",
    initialState,
    reducers: {
        setFundData(state, action) {
            state.fundData = action.payload
        },
        setFundPaymentLoader(state, action) {
            state.fundPaymentLoader = action.payload
        },
        setBuyWithCardData(state, action) {
            state.buyWithCardData = action.payload
        },
        setBuyWithCardLoader(state, action) {
            state.buyWithCardLoader = action.payload
        },
        setRewardData(state, action) {
            state.RewardData = action.payload
        },
        setRewardLoader(state, action) {
            state.RewardLoader = action.payload
        },
        setRechargeCard(sate, action) {
            sate.RechargeCard = action.payload
        },
        setRechargeLoader(state, action) {
            state.RechargeCardLoader = action.payload
        },
        setAutoRecharge(state, action) {
            state.autoRechargeHistory = action.payload
        },
        setAutoRechargeLoader(state, action) {
            state.autoRechargeLoader = action.payload
        },
        setReceiverTransactionHistory(state, action) {
            state.receiverTransactionHistory = action.payload
        },
        setReceiverTransactionLoader(state, action) {
            state.receiverTransactionLoader = action.payload
        },
        setSenderTransactionHistory(state, action) {
            state.senderTransactionHistory = action.payload
        },
        setSenderTransactionLoader(state, action) {
            state.senderTransactionLoader = action.payload
        },
        setBuyWithPaypal(state, action) {
            state.buyWithPaypalHistory = action.payload
        },
        setBuyWithPaypalLoader(state, action) {
            state.buyWithPaypalLoader = action.payload
        }
    }
})

export const {
    setFundData,
    setFundPaymentLoader,
    setBuyWithCardData,
    setBuyWithCardLoader,
    setRewardData,
    setRewardLoader,
    setRechargeCard,
    setRechargeLoader,
    setAutoRecharge,
    setAutoRechargeLoader,
    setReceiverTransactionHistory,
    setReceiverTransactionLoader,
    setSenderTransactionHistory,
    setSenderTransactionLoader,
    setBuyWithPaypal,
    setBuyWithPaypalLoader
} = customerDataSlice.actions
export default customerDataSlice.reducer
