import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    allGroup: [],
    groupLoader: null
}

const manageGroup = createSlice({
    name: "manageGroup",
    initialState,
    reducers: {
        setAllGroup(state, action) {
            state.allGroup = action.payload
        },
        setGroupLoader(state, action) {
            state.groupLoader = action.payload
        },
        deleteGroupList(state, action) {
            const newData = state.allGroup.filter(item => {
                return item?.group_id !== action.payload
            })
            state.allGroup = newData
        },
        addGroupList(state, action) {
            state.allGroup = [action.payload, ...state.allGroup]
        },
        editGroupList(state, action) {
            state.allGroup.map((item, i) => {
                item?.group_id !== action.payload
                if (item?.group_id === action.payload?.group_id) {
                    state.allGroup[i] = action.payload
                    return
                }
            })
        }
    }
})

export const { setAllGroup, setGroupLoader, deleteGroupList, addGroupList, editGroupList } = manageGroup.actions
export default manageGroup.reducer
