/* eslint-disable object-shorthand */
/* eslint-disable multiline-ternary */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    singleTemplateMms: {
        file: [],
        message: ""
    },
    template: null,
    singleMmsHistory: [],
    singleMmsHistoryLoader: null,
    groupMmsHistory: [],
    groupMmsHistoryLoader: null,
    countryMmsHistory: [],
    countryMmsHistoryLoader: null,
    templateMmsHistory: [],
    templateMmsHistoryLoader: null
}

const manageMms = createSlice({
    name: "manageMms",
    initialState,
    reducers: {
        setSingleTemplateMms(state, action) {
            state.singleTemplateMms = action.payload
        },
        setMmsTemplate(state, action) {
            state.template = action.payload
        },
        setSingleMmsHistory(state, action) {
            state.singleMmsHistory = action.payload
        },
        setSingleMmsHistoryLoader(state, action) {
            state.singleMmsHistoryLoader = action.payload
        },
        setGroupMmsHistory(state, action) {
            state.groupMmsHistory = action.payload
        },
        setGroupMmsHistoryLoader(state, action) {
            state.groupMmsHistoryLoader = action.payload
        },
        setCountryMmsHistory(state, action) {
            state.countryMmsHistory = action.payload
        },
        setCountryMmsHistoryLoader(state, action) {
            state.countryMmsHistoryLoader = action.payload
        },
        setTemplateMmsHistory(state, action) {
            state.templateMmsHistory = action.payload
        },
        setTemplateMmsHistoryLoader(state, action) {
            state.templateMmsHistoryLoader = action.payload
        },
        addMmsTemplateToStore(state, action) {
            state.templateMmsHistory.unshift(action.payload)
        },
        deleteMmsTemplates(state, action) {
            const newData = state.templateMmsHistory.filter((item) => {
                return item?.id !== action.payload
            })
            state.templateMmsHistory = newData
        },
        editMmsTemplateStore(state, action) {
            const { id, title, message, files } = action.payload

            const elemToUpdate = state.templateMmsHistory.findIndex((item) => item.id === id)
            const newArr = [
                ...state.templateMmsHistory.slice(0, elemToUpdate), // everything before current post
                {
                    ...state.templateMmsHistory[elemToUpdate],
                    image_url: files?.length
                        ? [...state.templateMmsHistory[elemToUpdate]?.image_url, ...files]
                        : [...state.templateMmsHistory[elemToUpdate]?.image_url],
                    message: message,
                    name: title
                },
                ...state.templateMmsHistory.slice(elemToUpdate + 1) // everything after current post
            ]
            // console.log(newArr)
            state.templateMmsHistory = newArr
        },
        singleMmsAdded(state, action) {
            state.singleMmsHistory.unshift(action.payload)
        },
        groupMmsAdded(state, action) {
            state.groupMmsHistory.unshift(action.payload)
        },
        countryMmsAdded(state, action) {
            state.countryMmsHistory.unshift(action.payload)
        }
    }
})

export const {
    setSingleTemplateMms,
    setMmsTemplate,
    setSingleMmsHistory,
    setSingleMmsHistoryLoader,
    setGroupMmsHistory,
    setGroupMmsHistoryLoader,
    setCountryMmsHistory,
    setCountryMmsHistoryLoader,
    setTemplateMmsHistory,
    setTemplateMmsHistoryLoader,
    deleteMmsTemplates,
    singleMmsAdded,
    groupMmsAdded,
    countryMmsAdded,
    editMmsTemplateStore,
    addMmsTemplateToStore
} = manageMms.actions
export default manageMms.reducer
