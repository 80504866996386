import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    manageRate: [],
    manageRateLoader: null,
    manageRateHistory: [],
    manageRateHistoryLoader: null,
    manageProvider: [],
    manageProviderLoader: null,
    manageProviderHistory: [],
    manageProviderHistoryLoader: null,
    customerRouter: []
}

const manageData = createSlice({
    name: "manageData",
    initialState,
    reducers: {
        setManageRate(state, action) {
            state.manageRate = action.payload
        },
        setManageRateLoader(state, action) {
            state.manageRateLoader = action.payload
        },
        deleteManageRate(state, action) {
            // state.manageRateHistory
            const newArr = state.manageRate.filter((data) => {
                return data?.prefix !== action.payload
            })
            state.manageRate = newArr
        },
        setManageRateHistory(state, action) {
            state.manageRateHistory = action.payload
        },
        setManageRateHistoryLoader(state, action) {
            state.manageRateHistoryLoader = action.payload
        },
        deleteManageRateHistory(state, action) {
            // state.manageRateHistory
            const newArr = state.manageRateHistory.filter((data) => {
                return data?.history_id !== action.payload
            })
            state.manageRateHistory = newArr
        },
        addManageRateHistory(state, action) {
            state.manageRateHistory = [action.payload, ...state.manageRateHistory]
        },
        updateManageRateHistory(state, action) {
            state.manageRateHistory.map((data, i) => {
                if (data?.history_id === action.payload?.history_id) {
                    state.manageRateHistory[i] = action.payload
                }
            })
        },
        setManageProvider(state, action) {
            state.manageProvider = action.payload
        },
        setManageProviderLoader(state, action) {
            state.manageProviderLoader = action.payload
        },
        setManageProviderHistory(state, action) {
            state.manageProviderHistory = action.payload
        },
        setManageProviderHistoryLoader(state, action) {
            state.manageProviderHistoryLoader = action.payload
        },
        deleteManageProviderHistory(state, action) {
            const newDate = state.manageProviderHistory.filter((data) => {
                return data?.history_id !== action.payload
            })
            state.manageProviderHistory = newDate
        },
        addManageProviderHistory(state, action) {
            state.manageProviderHistory = [action.payload, ...state.manageProviderHistory]
        },
        updateManageProviderHistory(state, action) {
            state.manageProviderHistory.map((data, i) => {
                if (data?.history_id === action.payload?.history_id) {
                    state.manageProviderHistory[i] = action.payload
                }
            })
        },
        editManageRateRdx(state, action) {
            state.manageRate.map((data, i) => {
                if (data?.id === action.payload.id) {
                    state.manageRate[i] = action.payload
                }
            })
        },
        editManageProviderRdx(state, action) {
            state.manageProvider.map((data, i) => {
                if (data?.id === action.payload.id) {
                    state.manageProvider[i] = action.payload
                }
            })
        },
        setManageCustomerRouter(state, action) {
            state.customerRouter = action.payload
        }
    }
})

export const {
    setManageRate,
    setManageRateLoader,
    setManageRateHistory,
    setManageRateHistoryLoader,
    deleteManageRate,
    deleteManageRateHistory,
    setManageProvider,
    setManageProviderLoader,
    setManageProviderHistory,
    setManageProviderHistoryLoader,
    deleteManageProviderHistory,
    updateManageRateHistory,
    addManageRateHistory,
    addManageProviderHistory,
    updateManageProviderHistory,
    editManageRateRdx,
    editManageProviderRdx,
    setManageCustomerRouter
} = manageData.actions
export default manageData.reducer
