/* eslint-disable  object-shorthand */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    callLog: [],
    callLogLoader: null
}

const callLog = createSlice({
    name: "callLog",
    initialState,
    reducers: {
        setCallLog(state, action) {
            state.callLog = action.payload
        },
        setCallLogLoader(state, action) {
            state.callLogLoader = action.payload
        }
    }
})

export const {
    setCallLog,
    setCallLogLoader
} = callLog.actions
export default callLog.reducer
