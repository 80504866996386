import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    deletePhoneNumber: [],
    hideDeletePhoneNumberPopup: null
}

const phoneNumber = createSlice({
    name: "phoneNumber",
    initialState,
    reducers: {
        setDeletePhoneNumbers(state, action) {
            state.deletePhoneNumber = action.payload
        },
        setHideDeletePhoneNumberPopup(state, action) {
            state.hideDeletePhoneNumberPopup = action.payload
        }
    }
})

export const { setDeletePhoneNumbers, setHideDeletePhoneNumberPopup } = phoneNumber.actions
export default phoneNumber.reducer
