/* eslint-disable  object-shorthand */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    loginHistory: [],
    loginHistoryLoader: null
}

const loginHistory = createSlice({
    name: "loginHistory",
    initialState,
    reducers: {
        setLoginHistory(state, action) {
            state.loginHistory = action.payload
        },
        setLoginHistoryLoader(state, action) {
            state.loginHistoryLoader = action.payload
        }
    }
})

export const {
    setLoginHistory,
    setLoginHistoryLoader
} = loginHistory.actions
export default loginHistory.reducer
