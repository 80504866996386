import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    userData: [],
    spacificData: {},
    userLoaderStatus: null,
    spacificUserData: {},
    deletedUsersData: [],
    deletedUserLoader: null
}

const userDataSlice = createSlice({
    name: "userData",
    initialState,
    reducers: {
        setUserData(state, action) {
            state.userData = action.payload
        },
        setSpacificData(state, action) {
            state.spacificData = action.payload
        },
        setLoaderStatus(state, action) {
            state.userLoaderStatus = action.payload

        },
        setSpacificUserData(state, action) {
            state.spacificUserData = action.payload
        },
        setDeletedUsersData(state, action) {
            state.deletedUsersData = action.payload
        },
        setDeletedUserLoader(state, action) {
            state.deletedUserLoader = action.payload
        },
        editUserDatal(state, action) {
            state.userData.map((data, i) => {
                if (data?.id === action.payload?.id) {
                    state.userData[i] = action.payload
                }
            })
        },
        addUserRdx(state, action) {
            state.userData = [action.payload, ...state.userData]
        },
        deleteUserRdx(state, action) {
            const filter = state.userData.filter((data) => {
                return data?.user_id !== action.payload
            })
            state.userData = filter
        },
        addDeleteUserRdx(state, action) {
            state.deletedUsersData = [action.payload, ...state.deletedUsersData]
        },
        editUserRdx(state, action) {
            state.userData.map((data, i) => {
                if (data?.user_id === action.payload.user_id) {
                    state.userData[i] = action.payload
                }
            })
        }
    }
})

export const { setUserData, setSpacificData, setLoaderStatus, setSpacificUserData, setDeletedUserLoader, setDeletedUsersData, editUserDatal, addUserRdx, deleteUserRdx, addDeleteUserRdx, editUserRdx } = userDataSlice.actions
export default userDataSlice.reducer
