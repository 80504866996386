/* eslint-disable  object-shorthand */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    followMeAssignedData: [],
    followMeAssignedLoader: null,
    followMeAvailableData: [],
    followMeAvailableLoader: null,
    followMeHistoryData: [],
    followMeHistoryLoader: null
}

const followMe = createSlice({
    name: "followMe",
    initialState,
    reducers: {
        setFollowMeAssignedData(state, action) {
            state.followMeAssignedData = action.payload
        },
        setFollowMeAssignedLoader(state, action) {
            state.followMeAssignedLoader = action.payload
        },
        setDeleteFollowMeAssignedNumber(state, action) {
            const newArr = state.followMeAssignedData.filter((data) => {
                return data?.user_id !== action.payload
            })
            state.followMeAssignedData = newArr
        },
        setFollowMeAvailableData(state, action) {
            state.followMeAvailableData = action.payload
        },
        setFollowMeAvailableLoader(state, action) {
            state.followMeAvailableLoader = action.payload
        },
        setFollowMeAvailableStatus(state, action) {
            const { status, id } = action.payload

            // console.log("first", { status, id })

            const elemToUpdate = state.followMeAvailableData.findIndex((item) => item.id === id)
            const newArr = [
                ...state.followMeAvailableData.slice(0, elemToUpdate), // everything before current post
                {
                    ...state.followMeAvailableData[elemToUpdate],
                    status: status
                },
                ...state.followMeAvailableData.slice(elemToUpdate + 1) // everything after current post
            ]
            // console.log("newArr", newArr)
            state.followMeAvailableData = newArr
        },
        setFollowMeHistoryData(state, action) {
            state.followMeHistoryData = action.payload
        },
        setFollowMeHistoryLoader(state, action) {
            state.followMeHistoryLoader = action.payload
        },
        addFollowMeHistory(state, action) {
            state.followMeHistoryData = [action.payload, ...state.followMeHistoryData]
        },
        cancelService(state, action) {
            const filter = state.followMeAssignedData.filter(data => {
                return data.id !== action.payload
            })
            state.followMeAssignedData = filter
        },
        addInAvailableData(state, action) {
            state.followMeAvailableData = [action.payload, ...state.followMeAvailableData]
        },
        editFollowMeNumberRdx(state, action) {
            state.followMeAssignedData.map((data, i) => {
                if (data?.id === action?.payload?.id) {
                    state.followMeAssignedData[i] = action.payload
                }
            })
        }
    }
})

export const {
    setFollowMeAssignedData,
    setFollowMeAssignedLoader,
    setDeleteFollowMeAssignedNumber,
    setFollowMeAvailableData,
    setFollowMeAvailableLoader,
    setFollowMeAvailableStatus,
    setFollowMeHistoryData,
    setFollowMeHistoryLoader,
    addFollowMeHistory,
    cancelService,
    addInAvailableData,
    editFollowMeNumberRdx
} = followMe.actions
export default followMe.reducer
