// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import userData from "./slice/userData"
import customerData from "./slice/customerData"
import partner from "./slice/partner"
import phoneNumber from "./slice/phoneNumber"
import voucher from "./slice/voucher"
import accessPrivileges from "./slice/accessPrivileges"
import global from "./slice/global"
import manageData from "./slice/manageData"
import manageSms from "./slice/manageSms"
import manageEmail from "./slice/manageEmail"
import manageVoice from "./slice/manageVoice"
import manageMms from "./slice/manageMms"
import manageGroup from "./slice/manageGroup"
import followMe from "./slice/followMe"
import manageAlerts from "./slice/manageAlerts"
import manageCards from "./slice/manageCards"
import localNumbers from "./slice/localNumbers"
import loginHistory from "./slice/loginHistory"
import referFriend from "./slice/referFriend"
import callLog from './slice/callLog'

const rootReducer = {
    navbar,
    layout,
    userData,
    partner,
    phoneNumber,
    voucher,
    accessPrivileges,
    global,
    manageData,
    customerData,
    manageSms,
    manageEmail,
    manageVoice,
    manageMms,
    manageGroup,
    followMe,
    manageAlerts,
    manageCards,
    localNumbers,
    loginHistory,
    referFriend,
    callLog
}

export default rootReducer
