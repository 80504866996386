import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    blockCountryDeleted: false,
    global2faStatus: false,
    globalCreditsData: {},
    sendEmailReminder: false,
    sendTextReminder: false,
    companyInformation: {
        data: {},
        org: {}
    }
}

const global = createSlice({
    name: "global",
    initialState,
    reducers: {
        setBlockCountryDeleted(state, action) {
            state.blockCountryDeleted = action.payload
        },
        setGlobal2faStatus(state, action) {
            state.global2faStatus = action.payload
        },
        setGlobalCreditData(state, action) {
            state.globalCreditsData = action.payload
        },
        setGlobalSendReminderEmail(state, action) {
            state.sendEmailReminder = action.payload
        },
        setGlobalSendReminderText(state, action) {
            state.sendTextReminder = action.payload
        },
        setCompanyInformation(state, action) {
            state.companyInformation = action.payload
        }
    }
})

export const { setCompanyInformation, setBlockCountryDeleted, setGlobal2faStatus, setGlobalCreditData, setGlobalSendReminderEmail, setGlobalSendReminderText } = global.actions
export default global.reducer
