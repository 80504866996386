import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accessPrivileges: [],
  accessLoader: null,
  adminAccess: {},
  userEditData: [],
  editAccessPriv: {
    accessPrivileges: 'no_access',
    backOfficeFunding: 'no_access',
    callHistory: 'no_access',
    dashboard: 'no_access',
    globalSettings: 'no_access',
    localNumbers: 'no_access',
    loginHistory: 'no_access',
    manageAlerts: 'no_access',
    manageCards: 'no_access',
    manageCustomers: 'no_access',
    manageEmails: 'no_access',
    manageGroups: 'no_access',
    manageMMS: 'no_access',
    managePartners: 'no_access',
    manageProviders: 'no_access',
    manageRates: 'no_access',
    manageSMS: 'no_access',
    manageVoice: 'no_access',
    myCallsFollowMe: 'no_access',
    referFriend: 'no_access',
    voucherHistory: 'no_access'
  }
}

const accessPrivileges = createSlice({
  name: 'accessPrivileges',
  initialState,
  reducers: {
    setAccessPrivileges(state, action) {
      state.accessPrivileges = action.payload
    },
    setAccessLoader(state, action) {
      state.accessLoader = action.payload
    },
    setAdminAccess(state, action) {
      state.adminAccess = action.payload
    },
    setUserEditData(state, action) {
      state.userEditData = action.payload
    },
    deleteAccess(state, action) {
      state.accessPrivileges = state.accessPrivileges.filter((data) => {
        return data.user_id !== action.payload
      })
    },
    addAccess(state, action) {
      state.accessPrivileges = [action.payload, ...state.accessPrivileges]
    },
    updateAccess(state, action) {
      state.accessPrivileges.map((data, i) => {
        if (data?.id === action.payload?.id) {
          state.accessPrivileges[i] = action.payload
        }
      })
    },
    setEditPrev(state, action) {
      state.editAccessPriv = action.payload
    }
  }
})

export const {
  setEditPrev,
  updateAccess,
  addAccess,
  setAccessPrivileges,
  setAccessLoader,
  setAdminAccess,
  setUserEditData,
  deleteAccess
} = accessPrivileges.actions
export default accessPrivileges.reducer
