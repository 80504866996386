import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    fundData: [],
    voucherLoader: null,
    buyWithCardData: [],
    buyWithCardLoader: null,
    RewardData: [],
    RewardLoader: null,
    RechargeCard: [],
    RechargeCardLoader: null,
    autoRechargeHistory: [],
    autoRechargeLoader: null,
    transactionHistory: [],
    transactionLoader: null,
    buyWithPaypalHistory: [],
    buyWithPaypalLoader: null
}

const voucher = createSlice({
    name: "voucher",
    initialState,
    reducers: {
        setFundData(state, action) {
            state.fundData = action.payload
        },
        setVoucheLoaderStatus(state, action) {
            state.voucherLoader = action.payload
        },
        setBuyWithCardData(state, action) {
            state.buyWithCardData = action.payload
        },
        setBuyWithCardLoader(state, action) {
            state.buyWithCardLoader = action.payload
        },
        setRewardData(state, action) {
            state.RewardData = action.payload
        },
        setRewardLoader(state, action) {
            state.RewardLoader = action.payload
        },
        setRechargeCard(sate, action) {
            sate.RechargeCard = action.payload
        },
        setRechargeLoader(state, action) {
            state.RechargeCardLoader = action.payload
        },
        setAutoRecharge(state, action) {
            state.autoRechargeHistory = action.payload
        },
        setAutoRechargeLoader(state, action) {
            state.autoRechargeLoader = action.payload
        },
        setTransactionHistory(state, action) {
            state.transactionHistory = action.payload
        },
        setTransactionLoader(state, action) {
            state.transactionLoader = action.payload
        },
        setBuyWithPaypal(state, action) {
            state.buyWithPaypalHistory = action.payload
        },
        setBuyWithPaypalLoader(state, action) {
            state.buyWithPaypalLoader = action.payload
        }
    }
})

export const { setFundData, setVoucheLoaderStatus, setBuyWithCardData, setBuyWithCardLoader, setRewardData, setRewardLoader, setRechargeCard, setRechargeLoader, setAutoRecharge, setAutoRechargeLoader, setTransactionHistory, setTransactionLoader, setBuyWithPaypal, setBuyWithPaypalLoader } = voucher.actions
export default voucher.reducer
