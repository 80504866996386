/* eslint-disable object-shorthand */

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    singleTemplateEmail: {
        subject: "",
        body: ""
    },
    template: null,
    singleEmailHistory: [],
    singleEmailHistoryLoader: null,
    groupEmailHistory: [],
    groupEmailHistoryLoader: null,
    countryEmailHistory: [],
    countryEmailHistoryLoader: null,
    templateEmailHistory: [],
    templateEmailHistoryLoader: null
}

const manageEmail = createSlice({
    name: "manageEmail",
    initialState,
    reducers: {
        setSingleTemplateEmail(state, action) {
            state.singleTemplateEmail = action.payload
        },
        setEmailTemplate(state, action) {
            state.template = action.payload
        },
        setSingleEmailHistory(state, action) {
            state.singleEmailHistory = action.payload
        },
        setSingleEmailHistoryLoader(state, action) {
            state.singleEmailHistoryLoader = action.payload
        },
        setGroupEmailHistory(state, action) {
            state.groupEmailHistory = action.payload
        },
        setGroupEmailHistoryLoader(state, action) {
            state.groupEmailHistoryLoader = action.payload
        },
        setCountryEmailHistory(state, action) {
            state.countryEmailHistory = action.payload
        },
        setCountryEmailHistoryLoader(state, action) {
            state.countryEmailHistoryLoader = action.payload
        },
        setTemplateEmailHistory(state, action) {
            state.templateEmailHistory = action.payload
        },
        setTemplateEmailHistoryLoader(state, action) {
            state.templateEmailHistoryLoader = action.payload
        },
        addEmailTemplateToStore(state, action) {
            state.templateEmailHistory.unshift(action.payload)
        },
        deleteEmailTemplates(state, action) {
            const newData = state.templateEmailHistory.filter((item) => {
                return item?.id !== action.payload
            })
            state.templateEmailHistory = newData
        },
        editEmailTemplateStore(state, action) {
            const { id, message, title } = action.payload
            const elemToUpdate = state.templateEmailHistory.findIndex((item) => item.id === id)
            const newArr = [
                ...state.templateEmailHistory.slice(0, elemToUpdate), // everything before current post
                {
                    ...state.templateEmailHistory[elemToUpdate],
                    subject: title,
                    message: message
                },
                ...state.templateEmailHistory.slice(elemToUpdate + 1) // everything after current post
            ]
            console.log(newArr)
            state.templateEmailHistory = newArr
        },
        editEmailTemplateRdx(state, action) {
            state.templateEmailHistory.map((data, i) => {
                if (data?.id === action.payload?.id) {
                    state.templateEmailHistory[i] = action.payload
                }
            })
        },
        singleEmailAdded(state, action) {
            const newData = [action.payload, ...state.singleEmailHistory]
            state.singleEmailHistory = newData
        },
        groupEmailAdded(state, action) {
            const newData = [action.payload, ...state.groupEmailHistory]
            state.groupEmailHistory = newData
        },
        countryEmailAdded(state, action) {
            const newData = [action.payload, ...state.countryEmailHistory]
            state.countryEmailHistory = newData
        }
    }
})

export const {
    setSingleTemplateEmail,
    setEmailTemplate,
    setSingleEmailHistory,
    setSingleEmailHistoryLoader,
    setGroupEmailHistory,
    setGroupEmailHistoryLoader,
    setCountryEmailHistory,
    setCountryEmailHistoryLoader,
    setTemplateEmailHistory,
    setTemplateEmailHistoryLoader,
    deleteEmailTemplates,
    singleEmailAdded,
    groupEmailAdded,
    countryEmailAdded,
    editEmailTemplateStore,
    addEmailTemplateToStore,
    editEmailTemplateRdx
} = manageEmail.actions
export default manageEmail.reducer
