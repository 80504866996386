import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    template: null,
    manageAlertTemplateContent: {
        subject: '',
        message: '',
        file: null
    },
    singleAlerts: [],
    singleAlertsLoader: null,
    groupAlerts: [],
    groupAlertsLoader: null,
    countryAlerts: [],
    countryAlertsLoader: null,
    allTemplate: [],
    allTemplateLoader: null
}

const manageAlerts = createSlice({
    name: "manageAlerts",
    initialState,
    reducers: {
        setAlertTemplate(state, action) {
            state.template = action.payload
        },
        setManageAlertTemplateContent(state, action) {
            state.manageAlertTemplateContent = action.payload
        },
        setSingleAlerts(state, action) {
            state.singleAlerts = action.payload
        },
        setSingleAlertsLoader(state, action) {
            state.singleAlertsLoader = action.payload
        },
        setGroupAlerts(state, action) {
            state.groupAlerts = action.payload
        },
        setGroupAlertsLoader(state, action) {
            state.groupAlertsLoader = action.payload
        },
        setCountryAlerts(state, action) {
            state.countryAlerts = action.payload
        },
        setCountryAlertsLoader(state, action) {
            state.countryAlertsLoader = action.payload
        },
        setAllTemplate(state, action) {
            state.allTemplate = action.payload
        },
        setTemplateLoader(state, action) {
            state.allTemplateLoader = action.payload
        },
        editTemplate(state, action) {
            state.allTemplate.map((data, index) => {
                if (data?.template_id === action.payload?.template_id) {
                    state.allTemplate[index] = action.payload
                }
            })
        },
        deleteAlertTemplateFromExist(state, action) {
            const filter = state.allTemplate.filter(data => {
                return data?.template_id !== action.payload
            })
            console.log(filter)
            console.log('actionactionaction', action.payload)
            state.allTemplate = filter
        },
        addAlertTemplate(state, action) {
            state.allTemplate = [action.payload, ...state.allTemplate]
        },
        addSingleAlert(state, action) {
            state.singleAlerts = [action.payload, ...state.singleAlerts]
        },
        addGroupAlert(state, action) {
            state.groupAlerts = [action.payload, ...state.groupAlerts]
        }
    }
})

export const {
    setAlertTemplate,
    setManageAlertTemplateContent,
    setSingleAlerts,
    setSingleAlertsLoader,
    setGroupAlerts,
    setGroupAlertsLoader,
    setCountryAlerts,
    setCountryAlertsLoader,
    setAllTemplate,
    setTemplateLoader,
    editTemplate,
    deleteAlertTemplateFromExist,
    addAlertTemplate,
    addSingleAlert,
    addGroupAlert
} = manageAlerts.actions
export default manageAlerts.reducer
