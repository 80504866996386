import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  partner: [],
  partnerLoaderStatus: null
}

const partners = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setPartner(state, action) {
      state.partner = action.payload
    },
    setPartnerLoaderStatus(state, action) {
      state.partnerLoaderStatus = action.payload
    },
    editPartnerDatas(state, action) {
      const index = state.partner.findIndex(
        (partner) => partner.id === action.payload.id
      )
      if (index !== -1) {
        state.partner[index] = action.payload
      }
    },
    addPartnerRdx(state, action) {
      state.partner = [action.payload, ...state.partner]
    }
  }
})

export const {
  setPartner,
  setPartnerLoaderStatus,
  editPartnerDatas,
  addPartnerRdx
} = partners.actions
export default partners.reducer
